import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  signal,
  WritableSignal,
} from '@angular/core';

import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { RootState } from '@store/index';

import { selectAppLoading } from '@store/misc/loading-indicator.selectors';

@Component({
  standalone: true,
  selector: 'dsp-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() set loading(loading: boolean) {
    this.$loading.set(loading ?? false);
    this.cd.markForCheck();
  }

  public $loading: WritableSignal<boolean> = signal(false);

  constructor(
    private store: Store<RootState>,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store
      .pipe(select(selectAppLoading), debounceTime(200), distinctUntilChanged())
      .subscribe(loading => {
        this.$loading.set(loading ?? false);
        this.cd.markForCheck();
      });
  }
}
